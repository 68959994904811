<template>
  <div>
    <div class="columns is-centered py-6">
      <div class="column is-12-mobile is-10-tablet is-8-desktop is-6-widescreen is-6-fullhd mb-4 px-6">
        <div class="container has-text-centered has-text-weight-semibold is-size-5 mb-3 mt-1">
          Please check if you are using an in-app browser.
        </div>
        <!-- <div class="container has-text-grey is-size-6 mb-4">
          If you clicked on a link or open from a QR-code app, your phone may sometimes use an in-app browser. We do not encourage the use of in-app browsers as the game progress cannot be saved.
        </div> -->
        <div class="container">
          <div class="is-size-6 mb-2"><b>Step 1:</b> Check if you can edit the URL.</div>
          <div class="is-size-6"><b>Step 2a:</b> If you can edit the URL, it means that you are not using the in-app browser. Great job!</div>
          <img
          style="max-height: 450px"
          class="fit mb-2"
          src="https://firebasestorage.googleapis.com/v0/b/monabrun-cff9c.appspot.com/o/appAssets%2FIn%20App%20Browser%20Tutorial%2Fbrowserexample.png?alt=media&token=7d7b2cd6-7a79-4386-bed5-1d234afee601" />
          <div class="is-size-6"><b>Step 2b:</b> If you cannot edit the URL, it is an in-app browser. Use your phone browser instead.</div>
          <img
          style="height: 450px"
          class="fit mb-2"
          src="https://firebasestorage.googleapis.com/v0/b/monabrun-cff9c.appspot.com/o/appAssets%2FIn%20App%20Browser%20Tutorial%2Finappexample.png?alt=media&token=5c49d3ac-fe25-490c-8a5e-b7a35ace89aa" />
        </div>
        <div class="container has-text has-text-weight-semibold is-size-5 mb-3 mt-1">
          Once you have done either Step 2a or 2b, click the green square button below.
        </div>
        <div class="container has-text-centered mt-0">
          <div @click="exit()" class="button is-success has-text-weight-semibold">
            <i class="fas fa-hand-point-up mr-2"></i>
            Click here to proceed
          </div>
        </div>
        <div v-if="showScrollDownButton" class="button has-text-weight-semibold is-small is-success scroll-button-modifier is-rounded">
          <i class="fas fa-angle-double-down mr-2"></i>
          Scroll down
          <i class="fas fa-angle-double-down ml-2"></i>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'InAppBrowserTutorial',
  data() {
    return {
      showScrollDownButton: true
    }
  },
  methods: {
    exit(){
      localStorage.playToursInAppAck = 'true'
      this.$router.go(-1)
    }
  },
  mounted () {
    window.onscroll = () => {
      if ((window.innerHeight + window.scrollY) >= document.body.scrollHeight*0.8) {
        this.showScrollDownButton = false
      }
    }
  }
}
</script>

<style>
.select-location-map{
  width: 100%;
  height: 450px;
}
</style>
